import React from 'react'

import Layout from '../components/layout'
export { Head } from '../components/head'

export default function Home() {
	return (
		<Layout
			bigHeader={true}
			altFooterText={true}
			showKittenList={true}
			showMoreInfo={true}
		/>
	)
}
